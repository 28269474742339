<template>
  <div>
    <v-row class="pa-0">
      <v-col cols="12" xl="6" lg="6" md="12" sm="12">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-center">
            <v-combobox
              v-model="suratProject"
              :items="suratProjectOptions"
              :rules="[v => !!v || 'Field is required']"
              label="*Project name"
              background-color="#F0F0F0"
            >
              <template v-slot:item="{ item }">
                <!-- {{item}} -->
                <span style="font-size: 20px">
                  {{ item }}
                </span>
              </template>
            </v-combobox>
            <v-btn
              @click="tambahProject"
              class="ml-2"
              small
              elevation="1"
              color="primary"
            >
              <v-icon dark v-if="!showTambah"> mdi-plus </v-icon>
              <v-icon dark v-if="showTambah"> mdi-minus </v-icon>
            </v-btn>
          </div>
          <div v-if="showTambah" class="d-flex flex-row align-center">
            <v-text-field
              label="Add new project name"
              v-model="suratProject"
              background-color="#F0F0F0"
            ></v-text-field>
            <v-btn
              @click="addNewProject()"
              class="ml-2"
              small
              elevation="1"
              color="primary"
            >
              OK
            </v-btn>
          </div>
        </div>

        <v-combobox
          v-model="kodeLayanan"
          :items="kodeLayananOptions"
          :rules="[v => !!v || 'Field is required']"
          label="*Kode Jenis layanan"
          background-color="#F0F0F0"
        >
          <template v-slot:item="{ item }">
            <!-- {{item}} -->
            <span style="font-size: 20px">
              {{ item }} - {{ kodeLayananDescription[item] }}
            </span>
          </template>
        </v-combobox>

        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="tanggal"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="tanggal"
              label="*Tanggal Nomor Surat"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :rules="[v => !!v || 'Field is required']"
              background-color="#F0F0F0"
            />
          </template>
          <v-date-picker
            v-model="tanggal"
            @input="updateTanggal"
            color="primary"
            elevation="2"
            no-title
            scrollable
            class="ma-2"
            background-color="#F0F0F0"
          >
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="menu = false"> Cancel </v-btn>
            <v-btn dark color="secondary" @click="$refs.menu.save(tanggal)">
              Set date
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-text-field
          label="Nomor Urut Surat"
          :value="nomorUrutWithZero"
          disabled
          background-color="#F0F0F0"
        ></v-text-field>

        <v-text-field
          v-model="operasionalSibernetik"
          label="Sibernetik Operational"
          disabled
          background-color="#F0F0F0"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="12" sm="12">
        <v-combobox
          v-model="kodeSuratKeluar"
          :items="kodeSuratKeluarOptions"
          :rules="[v => !!v || 'Field is required']"
          label="*Kode Surat keluar"
          background-color="#F0F0F0"
        >
          <template v-slot:item="{ item }">
            <!-- {{item}} -->
            <span style="font-size: 20px">
              {{ item }} - {{ kodeSuratKeluarDescription[item] }}
            </span>
          </template>
        </v-combobox>

        <v-text-field
          label="*User"
          v-model="userSurat"
          :rules="[v => !!v || 'Field is required']"
          background-color="#F0F0F0"
        ></v-text-field>

        <v-combobox
          v-model="docStatus"
          :items="docStatusOptions"
          :rules="[v => !!v || 'Field is required']"
          label="*Document Status"
          background-color="#F0F0F0"
        >
          <template v-slot:item="{ item }">
            <span style="font-size: 20px">
              {{ item }}
            </span>
          </template>
        </v-combobox>

        <v-textarea
          no-resize
          rows="2"
          label="*Descriptions"
          v-model="descriptions"
          :rules="[v => !!v || 'Field is required']"
          background-color="#F0F0F0"
        ></v-textarea>

        <v-file-input
          label="Select your file to upload content!"
          height="40px;"
          v-model="fileSurat"
          background-color="#F0F0F0"
        ></v-file-input>
      </v-col>

      <v-row class="px-4">
        <v-col cols="6">
          <v-btn
            @click="resetForm"
            v-if="showNomorSurat"
            outlined
            block
            color="error"
            :disabled="
              !kodeLayanan ||
              !docStatus ||
              !kodeSuratKeluar ||
              !tanggal ||
              !userSurat ||
              !suratProject ||
              !descriptions
            "
            >Clear Form</v-btn
          >
        </v-col>
        <v-col cols="6">
          <v-btn
            @click="generateNomorSurat"
            block
            light
            color="secondary"
            class="white--text"
          >
            Click for generate
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            @click="tableForm"
            class="rounded mt-2"
            dense
            block
            color="primary"
            >View Record Data</v-btn
          >
        </v-col>
      </v-row>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="1000"
        v-model="openrecord"
      >
        <v-card style="border-radius: 10px">
          <v-toolbar color="secondary" class="pa-0" dark>
            <div class="pa-2">
              <v-btn icon small @click="openrecord = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <span style="font-size: 24px; font-weight: 600">Record Data</span>
          </v-toolbar>
          <div class="py-2">
            <v-card-title class="pt-0">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                background-color="#F0F0F0"
              ></v-text-field>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-2"
                    elevation="1"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="fetchHistoricalDataTable()"
                  >
                    <v-icon dark> mdi-table-refresh </v-icon>
                  </v-btn>
                </template>
                <span>Refresh Table</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text style="font-size: 18px; overflow-y: auto !important">
              <v-data-table
                :headers="headersHistoricalData"
                :items="transformedData"
                :items-per-page="5"
                :search="search"
                sort-by="tanggal"
                sort-desc
                class="elevation-1"
                style="width: 100%; padding: 5px; border-radius: 10px"
              >
                <template v-slot:item.fileSurat="{ item }">
                  <a
                    v-if="item.fileSurat"
                    :href="urlJenisSuratGenerator(item.fileSurat)"
                    target="_blank"
                    >{{ item.fileSurat }}</a
                  >
                  <span v-else>-</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="editItem(item)"> mdi-pencil </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="opengenerate"
      >
        <v-card style="border-radius: 10px">
          <v-toolbar color="secondary" class="pa-0" dark>
            <div class="pa-2">
              <v-btn icon small @click="opengenerate = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
            <span style="font-size: 24px; font-weight: 600">NOMOR SURAT</span>
          </v-toolbar>
          <v-card-text class="py-10">
            <v-container class="d-flex" style="max-width: 100%">
              <div v-if="showNomorSurat" class="d-flex align-left flex-column">
                <div class="d-flex flex-row">
                  <span class="generate-style"
                    ><strong>Generate No:&nbsp;</strong></span
                  >
                  <span class="generate-style" ref="textToCopy">{{
                    nomorSurat
                  }}</span>
                  <v-btn
                    class="ml-2"
                    x-small
                    height="40px"
                    width="40px"
                    elevation="1"
                    color="primary"
                    @click="copyToClipboard"
                  >
                    <v-icon dark> mdi-clipboard-multiple-outline </v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-row">
                  <span class="generate-style"
                    ><strong>Created by:&nbsp;</strong></span
                  >
                  <span class="generate-style">{{ dataDiri.email }}</span>
                </div>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    
    <!-- Dialogs Edit Table-->

    <v-dialog v-model="openEditData" max-width="500px">
      <v-card style="border-radius: 10px">
        <v-toolbar color="secondary" class="pa-0" dark>
          <div class="pa-2">
            <v-btn icon small @click="openEditData = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <span style="font-size: 24px; font-weight: 600">Edit item</span>
        </v-toolbar>
        <v-card-text class="py-5">
          <v-container
            class="d-flex flex-column"
            style="max-width: 100%"
            v-if="bufferUpdateTable"
          >
            <v-combobox
              v-model="bufferUpdateTable[6]"
              :items="docStatusOptions"
              :rules="[v => !!v || 'Field is required']"
              label="*Document Status"
              background-color="#F0F0F0"
            >
              <template v-slot:item="{ item }">
                <span style="font-size: 20px">
                  {{ item }}
                </span>
              </template>
            </v-combobox>
            <span
              >File Uploaded :
              <a
                :href="urlJenisSuratGenerator(bufferUpdateTable[3])"
                target="_blank"
                >{{ bufferUpdateTable[3] }}</a
              >
            </span>
            <v-file-input
              label="*Reupload File"
              hint="Click button on right for reupload file"
              v-model="bufferFileReupload"
              :rules="[v => !!v || 'Field is required']"
              height="35px"
              background-color="#F0F0F0"
            >
              <template v-slot:append>
                <v-btn
                  small
                  class="mr-1"
                  elevation="1"
                  color="primary"
                  v-on:click="editTableFileReupload().then(() => {})"
                  :loading="loadingFileReupload"
                  :disabled="!bufferFileReupload"
                  ><v-icon>mdi-upload</v-icon>
                </v-btn>
              </template>
            </v-file-input>
            <v-textarea
              no-resize
              rows="2"
              label="*Descriptions"
              v-model="bufferUpdateTable[7]"
              :rules="[v => !!v || 'Field is required']"
              background-color="#F0F0F0"
            ></v-textarea>
            <v-btn
              elevation="1"
              color="primary"
              block
              v-on:click="submitEditItem()"
              :loading="loadingFileReupload"
              >Click for edit data
            </v-btn>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import { crewDigitalCard } from "../../assets/plugin/datacrew";
import { fetchMethod, postMethod } from "../../plugins/restAPI.js";
import baseUrl from "../../about.js";
import moment from "moment";
export default {
  components: {
    //
  },
  mounted() {
    let vm = this;
    // let dataDiri = crewDigitalCard(vm.$route.params.name)
    let sessionName = JSON.parse(sessionStorage.getItem("loginInfo"));
    if (sessionName) {
      // console.log('data masuk nih gan!', sessionName);
    }
    vm.dataDiri = sessionName;
    // console.log('kepanggil nih gan!', vm.dataDiri);
    // console.log('tanda tanda bahwa:', dataDiri);
    vm.fetchProjects();
    vm.fetchHistoricalDataTable();
  },
  data() {
    return {
      metadataSurat: [
        "tanggal",
        "dataDiri", //this.dataDiri,
        "nomorSurat", //this.nomorSurat,
        "fileSurat", //this.fileSurat,
        "suratProject", //this.suratProject,
        "userSurat", //this.userSurat,
        "docStatus",
        "description", //this.descriptions
      ],
      urlJenisSurat: "/generate-nomor/penomoran-internal",
      // urlJenisSurat: "/generate-surat-test/penomoran-internal",
      dataDiri: {},
      fileSurat: null,
      kodeLayanan: "",
      nomorUrut: 0, // Nomor Urut akan diatur secara otomatis
      kodeSuratKeluar: "",
      operasionalSibernetik: "OPS",
      descriptions: "",
      userSurat: "",
      tanggal: null,
      menu: false,
      modal: false,
      textToCopy: "",
      search: "",
      docStatus: "",
      docStatusOptions: ["Waiting full signoff", "Full Signoff", "On Check"],
      kodeLayananDescription: {
        Null: "",
        C01: "Surat Kuasa",
        C02: "Surat Pengangkatan",
        C03: "Surat Tugas",
        C04: "Surat Mandat",
        C05: "Surat Sertifikat",
        CO6: "Surat Pengantar",
        C07: "Surat Tanda Terima",
        C08: "Pembelian",
        C09: "Perjanjian Kerja",
      },
      kodeLayananOptions: [
        "",
        "C01",
        "C02",
        "C03",
        "C04",
        "C05",
        "CO6",
        "C07",
        "C08",
        "C09",
      ],
      kodeSuratKeluarDescription: {
        ST: "Surat Tugas/Tanda Terima",
        Ss: "Surat Sponsor/Visa",
        SP: "Surat Penunjukkan",
        SPm: "Surat Permohonan/Permintaan/Pakta Integritas",
        SK: "Surat Kuasa/Keputusan",
        SU: "Surat Undangan",
        SB: "Surat Balasan/Penjelasan",
        BAST: "Berita Acara Serah Terima",
        BAPP: "Berita Acara Penyerahan Pelaksanaan",
        BASO: "Berita Acara Siap Operasi",
        SPb: "Surat Pemberitahuan",
        SPj: "Surat Peminjaman",
        SPn: "Surat Pernyataan",
        SM: "Surat Mandat",
        SKet: "Surat Keterangan",
        SR: "Surat Rekomendasi",
        SB: "Surat Balasan/Penjelasan",
        SPPD: "Surat Perintah Perjalanan Dinas",
        SRT: "Sertifikat",
        PK: "Perjanjian Kerja",
        SPeng: "Surat Pengantar",
        SPH: "Surat Penawaran Harga",
        SD: "Surat Dukungan",
        SJ: "Surat Jalan",
        DO: "Delivery Order",
        PL: "Packing List",
        STt: "Surat Tanda Terima",
        SPk: "Surat Perkenalan",
        SPs: "Surat Persetujuan",
        NDA: "NDA",
      },
      kodeSuratKeluarOptions: [
        "ST",
        "Ss",
        "SP",
        "SPm",
        "SK",
        "SU",
        "SB",
        "BAST",
        "BAPP",
        "BASO",
        "SPb",
        "SPj",
        "SPn",
        "SM",
        "SKet",
        "SR",
        "SB",
        "SPPD",
        "SRT",
        "PK",
        "SPeng",
        "SPH",
        "SD",
        "SJ",
        "DO",
        "PL",
        "STt",
        "SPk",
        "SPs",
        "NDA",
      ],
      suratProject: "",
      suratProjectOptions: [],
      showTambah: false,
      showNomorSurat: true,
      nomorSurat: "",
      headersHistoricalData: [
        {
          text: "Tanggal",
          value: "tanggal",
          align: "start",
        },
        { text: "No. Surat", value: "nomorSurat" },
        { text: "PIC/CreateBy", value: "dataDiri.email" },
        { text: "User", value: "userSurat" },
        { text: "Project", value: "suratProject" },
        { text: "Descriptions", value: "description" },
        { text: "File Surat", value: "fileSurat" },
        { text: "Document Status", value: "docStatus" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dessertsHistoricalData: [],
      opengenerate: false,
      openrecord: false,
      openEditData: false,
      bufferUpdateTable: null,
      bufferFileReupload: null,
      loadingFileReupload: false,
    };
  },
  watch: {
    fileSurat(e) {
      console.log(e.name);
    },
  },
  computed: {
    nomorUrutWithZero() {
      // console.log(this.nomorUrut < 10, this.nomorUrut < 100)
      let zeroAdd = String(this.nomorUrut).padStart(3, "0");
      return zeroAdd; //zeroAdd.length > 3 ? zeroAdd.slice(zeroAdd.length - 3, );
    },
    transformedData() {
      return this.dessertsHistoricalData.map((row) => {
        console.log(row);
        return {
          // 'tanggal': moment(row[0]).utcOffset(7).format('DD-MM-YYYY HH:mm:ss Z'),//index sesuaiiin sama metadata
          // 'dataDiri': row[0].name,
          // 'userSurat': row[5],
          // 'suratProject': row[4],
          // 'description': row[6],
          // 'fileSurat': row[3],

          tanggal: moment(row[0], "X")
            .utcOffset(7)
            .format("DD-MM-YYYY HH:mm:ss Z"), //index sesuaiiin sama metadata
          dataDiri: row[1],
          nomorSurat: row[2],
          fileSurat: row[3], //editable
          suratProject: row[4],
          userSurat: row[5],
          docStatus: row[6], //editable
          description: row[7], //editable
          actions: [
            {
              icon: "mdi-pencil",
              tooltip: "Edit",
              onClick: () => this.editItem(row),
            },
          ], // kalo kau lihat ini cup, yang diedit bagian Description, docStatus, dan file lampiran(mungkin ditimpah yg lama) Suwun gan
        };
      });
    },
  },
  methods: {
    urlJenisSuratGenerator(item) {
      return baseUrl + this.urlJenisSurat + "/" + item;
    },
    generateNomorSurat() {
      if (
        !this.suratProject ||
        !this.kodeLayanan ||
        !this.tanggal ||
        !this.kodeSuratKeluar ||
        !this.userSurat ||
        !this.docStatus ||
        !this.descriptions
      ) {
        alert("Complete your form before submitting!");
        return;
      }

      if (!this.tanggal) {
        alert("Please set a date!");
        return;
      }
      // Implementasikan logika untuk menghasilkan nomor surat di sini
      // Anda dapat menggabungkan berbagai input sesuai kebutuhan Anda
      // Contoh: Kode Jenis Surat (A01), Tahun (2023), Nomor Urut (001)
      // Hasil: A01-2023-001
      // console.log('fileSurat :', this.fileSurat)
      // if (this.fileSurat) {
      this.checkIndexing().then((resChecking) => {
        const nomorSurat = `${this.kodeLayanan}/${this.nomorUrutWithZero}/${
          this.kodeSuratKeluar
        }/${this.operasionalSibernetik}/${this.getTahun()}`;
        // console.log('Nomor Surat Keluar:', nomorSurat);
        this.opengenerate = true;
        // this.open;
        this.showNomorSurat = true;
        this.nomorSurat = nomorSurat;
        // console.log('Ini bakal jadi JSON: ', JSON.stringify(this.nomorSurat));
        console.log("penomoran Internal :", this.suratProject);
        let dataToPost = [
          moment().format("X"), //0
          this.dataDiri, //1
          this.nomorSurat, //2
          this.fileSurat ? this.fileSurat.name : "", //3
          this.suratProject, //4
          this.userSurat, //5
          this.docStatus, //6
          this.descriptions, //7
          //user id,
          //nomor surat generated,
          //nama file yang mau diupload,
          //nama project,
          //nama user project
        ];
        // console.log('ini data to post: ', dataToPost);
        this.postBackMetadata(dataToPost).then((resPosting) => {
          // console.log('done posting')
          this.fetchHistoricalDataTable();
        });
      });

      // } else {
      // Tampilkan pesan kesalahan jika tidak ada file yang diunggah
      // alert("Please upload document or image first before go to the next step");
      // console.error('Silakan unggah file terlebih dahulu.');
      // }
    },
    async checkIndexing() {
      let urlIndexing = baseUrl + this.urlJenisSurat;
      try {
        const checkTheIndexing = await fetchMethod(
          urlIndexing + "/indexing.json"
        );
        const res = await checkTheIndexing;
        if (!res) {
          //new index starts from 015, added by 1
          this.nomorUrut = 16; //016
        } else {
          this.nomorUrut = res.current + 1;
        }

        let indexing = {
          current: this.nomorUrut,
          lastUpdate: moment().format("X"),
        };
        // post ulang
        const postBackIndexing = await postMethod(
          urlIndexing + "/",
          JSON.stringify(indexing),
          "indexing.json"
        );
        const resPost = await postBackIndexing;
        // console.log('post back indexing :', resPost)
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async postBackMetadata(dataToBePosted) {
      let url = baseUrl + this.urlJenisSurat;
      try {
        const getMetadata = await fetchMethod(url + "/metadata.json");
        const res = await getMetadata;
        let metadata;
        if (!res) {
          metadata = {
            metadata: this.metadataSurat,
            data: [dataToBePosted],
          };
        } else {
          metadata = res;
          metadata.data.push(dataToBePosted);
        }

        const postBackMetadata = await postMethod(
          url + "/",
          JSON.stringify(metadata),
          "metadata.json"
        );
        const postFile = this.fileSurat
          ? await postMethod(url + "/", this.fileSurat, this.fileSurat.name)
          : null;
        console.log(postFile); //for variable callback
        if (postBackMetadata) {
          // alert("successfuly added new surat");
          return true;
        } else {
          alert("failed adding new surat");
          return false;
        }
      } catch (error) {
        alert("something went wrong");
        console.log(error);
        return false;
      }
    },
    updateTanggal() {
      // Lakukan sesuatu saat tanggal diubah
      // console.log('Tanggal diubah:', this.tanggal);
    },
    getTahun() {
      // const now = new Date();
      let now = new Date(Date.parse(this.tanggal));
      // Fungsi untuk mendapatkan tahun terkini
      let monthRoman = this.romanize(now.getMonth() + 1);
      console.log(Date.now(), now);

      return monthRoman + "/" + now.getFullYear();
    },
    romanize(num) {
      if (!+num) return false;
      var digits = String(+num).split(""),
        key = [
          "",
          "C",
          "CC",
          "CCC",
          "CD",
          "D",
          "DC",
          "DCC",
          "DCCC",
          "CM",
          "",
          "X",
          "XX",
          "XXX",
          "XL",
          "L",
          "LX",
          "LXX",
          "LXXX",
          "XC",
          "",
          "I",
          "II",
          "III",
          "IV",
          "V",
          "VI",
          "VII",
          "VIII",
          "IX",
        ],
        roman = "",
        i = 3;
      while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
      return Array(+digits.join("") + 1).join("M") + roman;
    },
    // submitForm() {
    //   // Panggil fungsi untuk menghasilkan nomor surat
    //   this.nomorSurat = this.generateNomorSurat();
    // },
    resetForm() {
      this.kodeLayanan = ""; // Reset nilai kodeLayanan
      // this.nomorUrut = 1; Reset nilai nomorUrut ke 1
      this.kodeSuratKeluar = ""; // Reset nilai kodeSuratKeluar
      this.docStatus = "";
      // this.operasionalSibernetik = 'OPS'; // Reset nilai operasionalSibernetik
      this.tanggal = null; // Reset nilai tanggal
      this.fileSurat = "";
      this.userSurat = "";
      this.suratProject = "";
      this.descriptions = "";
      this.showNomorSurat = false; // Sembunyikan nomor surat
    },
    tableForm() {
      this.openrecord = true;
    },
    copyToClipboard() {
      // Assuming `this.nomorSurat` is the text you want to copy
      if (navigator.clipboard && window.isSecureContext) {
        // navigator.clipboard is available
        navigator.clipboard.writeText(this.nomorSurat).then(
          () => {
            alert(
              "Penomoran surat internal output has been copied to the clipboard"
            );
          },
          () => {
            alert("Copy failed");
          }
        );
      }
    },
    tambahProject() {
      this.showTambah = !this.showTambah;
      //
    },
    fetchProjects() {
      let url = baseUrl + this.urlJenisSurat + "/suratProjectOption.json";
      // console.log('url fetch :', url)
      fetchMethod(url).then((res) => {
        if (res) {
          this.suratProjectOptions = res;
        }
      });
    },
    addNewProject() {
      this.fetchProjects();
      //tunggu fetch project terbaru
      let newProject = this.suratProject;
      let searchForDouble = this.suratProjectOptions.filter(
        (el) => el.trim().toLowerCase() == newProject.trim().toLowerCase()
      );
      if (searchForDouble.length > 0) {
        alert("project name exist or similar");
        return;
      } else {
        this.suratProjectOptions.push(newProject);
        let urlToPost = baseUrl + this.urlJenisSurat + "/";
        console.log(urlToPost);
        postMethod(
          urlToPost,
          JSON.stringify(this.suratProjectOptions),
          "suratProjectOption.json"
        ).then((res) => {
          // console.log('posting new project', res)
          this.fetchProjects();
          alert(!res ? "failed" : "Successfully added");
        });
      }
    },
    fetchHistoricalDataTable() {
      let url = baseUrl + this.urlJenisSurat + "/metadata.json";
      // console.log('url fetch historical data :', url)
      fetchMethod(url).then((res) => {
        // console.log('res fetch historical data :', res)
        if (res) {
          this.dessertsHistoricalData = res.data;
        }
      });
    },
    async editTableFileReupload() {
      this.loadingFileReupload = true; //loading button

      let fileToUpload = this.bufferFileReupload;
      let urlToUpload = baseUrl + this.urlJenisSurat + "/";
      let postBack = await postMethod(
        urlToUpload,
        fileToUpload,
        fileToUpload.name
      );
      console.log(postBack, fileToUpload.name);
      if (postBack) {
        this.bufferUpdateTable.splice(3, 1, fileToUpload.name);
        console.log("file updated :", this.bufferUpdateTable);
        this.loadingFileReupload = false;
      } else {
        alert("failed to reupload file");
        this.loadingFileReupload = false;
      }
    },
    editItem(item) {
      console.log(item);
      let findData = this.dessertsHistoricalData.filter(
        (e) => e[2] == item.nomorSurat /*nomor surat*/
      );
      console.log(findData);
      if (findData.length > 0) {
        this.bufferUpdateTable = findData[0];
        this.openEditData = true;
      } else {
        alert("something went wrong");
      }

      // this.currentItem = item; // Assuming you have defined currentItem in your data to keep track of the current item being edited
      // Ensure this is the correct v-model for your dialog
    },
    submitEditItem() {
      // this.fetchHistoricalDataTable().then(res )
      //update the table before posting

      this.loadingFileReupload = true;

      let url = baseUrl + this.urlJenisSurat + "/";
      // console.log('url fetch historical data :', url)
      fetchMethod(url + "metadata.json").then((res) => {
        // console.log('res fetch historical data :', res)
        if (res) {
          console.log(res.data);
          // this.dessertsHistoricalData = res.data;
          let findIndex = res.data.findIndex(
            (e) => e[2] == this.bufferUpdateTable[2] /*nomor surat*/
          );
          console.log(findIndex);
          if (findIndex != -1) {
            res.data.splice(findIndex, 1, this.bufferUpdateTable);
            //post back
            postMethod(
              url,
              JSON.stringify(res), //last fetched
              "metadata.json"
            ).then((resPost) => {
              this.loadingFileReupload = false;
              if (resPost) {
                alert("Successfully reuploaded");
                //update table
                this.dessertsHistoricalData = res.data;

                this.bufferFileReupload = null;
                this.bufferUpdateTable = null;
                this.openEditData = false;
              } else {
                alert("failed to reupload");
              }
            });
          } else {
            this.loadingFileReupload = false;
            alert("something went wrong");
          }
        } else {
          this.loadingFileReupload = false;
          alert("something went wrong");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-autocomplete__content.v-menu__content {
  min-width: 250px !important;
}

.container {
  display: block;
  margin: auto;
  text-align: left;
  border-radius: 10px;
}

.idcard-page {
  background-color: #e8ebf0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  height: min-content;
}
.generate-style {
  font-size: 21px;
}
.v-text-field input {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 400;
}
.v-text-field label {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 500;
  letter-spacing: 1px !important;
}
.v-textarea label {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 500;
  letter-spacing: 1px !important;
}
.v-textarea input {
  font-family: "Noto Sans", sans-serif !important;
  font-weight: 400;
}
.v-messages__message {
  color: red;
}
</style>